import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const OriginalDoc = () => {
  const location = useLocation();

  // Extract the file from the state
  const { file } = location.state || {};
  const isCoreOnly = process.env.REACT_APP_ONLY_CORE === 'true';

  // Memoize the file URL to ensure it's stable
  const fileUrl = useMemo(() => {
    if(!isCoreOnly){
      return `${process.env.PUBLIC_URL}/Untitled design.pdf`; //Used for demo
    }
    if (file && file.type === 'application/pdf') {
      return URL.createObjectURL(file);
    }
    return null;
  }, [file,isCoreOnly]);

  // Clean up the URL object when the component unmounts to avoid memory leaks
  useEffect(() => {
    if (fileUrl) {
      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [fileUrl,isCoreOnly]);

  // Use useRef to store the defaultLayoutPlugin instance
  const defaultLayoutPluginInstance = useRef(defaultLayoutPlugin()).current;

  // Debug logging to monitor re-renders
  useEffect(() => {
    console.log('OriginalDoc component re-rendered');
  });

  return (
    <div style={{ height: '100%' }}>
      {fileUrl ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div style={{ height: '896px' }}>
            <Viewer
              fileUrl={fileUrl}
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        </Worker>
      ) : (
        <p>No PDF file available to preview.</p>
      )}
    </div>
  );
};

export default OriginalDoc;
