import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { RotatingLines } from "react-loader-spinner";

const WaitingTitle = styled.div`
  width: 314px;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WaitingIcon = styled.img`
  width: 99px;
  position: relative;
  height: 99px;
  overflow: hidden;
  flex-shrink: 0;
`;
const TagLineWaiting = styled.div`
  position: relative;
  font-size: 18px;
`;
const WaitingParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;
const WaitingFrame = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 414px;
  height: 896px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
  gap: 50px;
`;
const WaitingRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #f4efe9;
  height: 896px;
  overflow: hidden;
  text-align: center;
  font-size: 26px;
  color: #1d1928;
  font-family: Poppins;
`;


const images = [
  "/ciuservoice.svg",
  "/ciuservoice1.svg",
  "/tableruserquestion.svg",
  "/ciuservoicedoctor.svg",
  "/streamlineusercheckvalidate.svg"
];

const taglines = [
  "To have a voice",
  "To participate",
  "To ask the right questions",
  "To make good medical decisions",
  "To understand"
];

const WaitingScreen = () => {
  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1200); // Change image every 2 seconds

    return () => clearInterval(interval);
  }, []);


  return (
    <WaitingRoot>
      <WaitingFrame>
        <WaitingTitle>Empowering you</WaitingTitle>
        <WaitingParent>
        <WaitingIcon alt="" src={images[currentImageIndex]} />
          <TagLineWaiting>{taglines[currentImageIndex]}</TagLineWaiting>
        </WaitingParent>
        <RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />

      </WaitingFrame>
    </WaitingRoot>
  );
};

export default WaitingScreen;
