import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import HomeScreen from "./pages/HomeScreen";
import WaitingScreen from "./pages/EmpoweringYou";
import ReportReady from "./pages/ReportReady";
import Summary from "./pages/Summary";
import OriginalDocPage from "./pages/OriginalDocPage";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/empowering-you":
        title = "";
        metaDescription = "";
        break;
      case "/report-ready":
        title = "";
        metaDescription = "";
        break;
      case "/summary":
        title = "";
        metaDescription = "";
        break;
      default: 
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/empowering-you" element={<WaitingScreen />} />
      <Route path="/report-ready" element={<ReportReady />} />
      <Route path="/summary" element={<Summary />} />
      <Route path="/original-doc" element={<OriginalDocPage />} />
    </Routes>
  );
}
export default App;
