
import React from 'react';
import styled from 'styled-components';

const LangButton = ({ currentLang, targetLang, onClick, children }) => {
  if (currentLang === targetLang) {
    return null; // Don't render the button if the current language matches the target language
  }

  return (
    <StyledButton onClick={onClick}>
      {children}
    </StyledButton>
  );
};

// Styled button component
const StyledButton = styled.button`
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f27762;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0};
  }
`;

export default LangButton;
