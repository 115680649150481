import styled from "styled-components";
import { useState } from "react";


const WhatDoIHave = ({ findings }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const isCoreOnly = process.env.REACT_APP_ONLY_CORE === 'true';

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleVideo = () => {
    setIsVideoVisible(!isVideoVisible);
  };

  return (
    <FrameParentRoot>
      <WhatDoIHaveWrapper onClick={toggleCollapse}>
        <WhatDoI>What do I have?</WhatDoI>
        <div>{isCollapsed ? "▲" : "▼"}</div>
      </WhatDoIHaveWrapper>

      {!isCollapsed && (
        <>
          <YouAreA27YearsOldWithWrapper>
            <WhatDoI>{findings}</WhatDoI>
          </YouAreA27YearsOldWithWrapper>
          <WhereCanISeeThisWrapper>
          
            <ToggleButton onClick={toggleVideo}>
              <i className={`fas ${isVideoVisible ? "fa-times" : "fa-video"}`}></i>
            </ToggleButton>

            <WhereCanI>Where can I see this?</WhereCanI>
          </WhereCanISeeThisWrapper>
<VideoWrapper isCollapsed={!isVideoVisible}>
  {isCoreOnly ? (
    <GrapeVinePlaceHolder> Grapevine video Placeholder </GrapeVinePlaceHolder>
  ) : (
    <video width="100%" controls>
      <source src="what_do_i_have.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )}
</VideoWrapper>
          
        </>
      )}
    </FrameParentRoot>
  );
};


const WhatDoI = styled.div`
  position: relative;
`;

const GrapeVinePlaceHolder = styled.div`
  position: relative;
`;

const WhatDoIHaveWrapper = styled.div`
  border-radius: 0px 8px 8px 0px;
  background-color: #dbf0ff;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
`;

const YouAreA27YearsOldWithWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px;
  color: #000;
`;

const WhereCanI = styled.div`
  position: relative;
  font-weight: 500;
  color: #f27762;
`;

const WhereCanISeeThisWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Adjusted to space between icon and text */
  padding: 0px 15px;
  gap: 10px; /* Add gap for spacing */
`;

const FrameParentRoot = styled.div`
  align-self: stretch;
  box-shadow: 1px 1px 3px rgba(123, 123, 123, 0.25);
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0px;
  gap: 12px;
  text-align: left;
  font-size: 12px;
  color: #1882ae;
  font-family: Poppins;
`;

const VideoWrapper = styled.div`
  display: ${({ isCollapsed }) => (isCollapsed ? "none" : "block")};
  width: 100%;
  padding: 20px;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  font-size: 18px;
  cursor: pointer;
`;

export default WhatDoIHave;
