import styled from "styled-components";
import { useState } from "react";
import TaskItem from "./TaskItem";

const WhatDoI = styled.div`
  position: relative;
`;

const WhatDoINeedToDoWrapper = styled.div`
  border-radius: 0px 8px 8px 0px;
  background-color: #fef6ec;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  color: #d88311;
  cursor: pointer;
`;

const WhereCanI = styled.div`
  position: relative;
  font-weight: 500;
`;

const WhereCanISeeThisWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0px 20px;
  color: #f27762;
`;

const FrameParentRoot = styled.div`
  align-self: stretch;
  box-shadow: 1px 1px 3px rgba(123, 123, 123, 0.25);
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0px;
  gap: 12px;
  text-align: left;
  font-size: 12px;
  color: #000;
  font-family: Poppins;
`;

const WhatDoINeedToDo = ({ tasks }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <FrameParentRoot>
      <WhatDoINeedToDoWrapper onClick={toggleCollapse}>
        <WhatDoI>{`What do I need to do? `}</WhatDoI>
        <div>{isCollapsed ? '▼' : '▲'}</div>
      </WhatDoINeedToDoWrapper>
      {!isCollapsed && (
        <>
          {tasks.map((task, index) => (
            <TaskItem task={task['step']} index={index} key={`Task-${index}`} />
          ))}
          <WhereCanISeeThisWrapper>
            <WhereCanI>Where can I see this?</WhereCanI>
          </WhereCanISeeThisWrapper>
        </>
      )}
    </FrameParentRoot>
  );
};

export default WhatDoINeedToDo;
