import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const useTranslator = process.env.REACT_APP_DIRECT_TRANSLATION === 'true';

export const extractText = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    
    try {
        const response = await axios.post(`${apiUrl}/extract-text`, formData);
        if (response.status === 200) {
            return { success: true, text: response.data.text };
        } else {
            return { success: false, error: 'Failed to extract text' };
        }
    } catch (error) {
        console.error('Error:', error);
        return { success: false, error: error.message };
    }
};

export const getSummary = async (text, _lang) => {
      
    try {
        const response = await axios.post(`${apiUrl}/summary`, { text }, {
            headers: { 'Content-Type': 'application/json' }
        });
        let response_es;
        if(useTranslator){
            response_es = await axios.post(`${apiUrl}/translate_summary_spanish`, response.data, {
                headers: { 'Content-Type': 'application/json' }
            });
    
        } else{
            response_es = await axios.post(`${apiUrl}/summary_spanish`,{ text }, {
            headers: { 'Content-Type': 'application/json' }
        });
        }

        const data = {'en': response.data, 'es':response_es.data}
        if (response.status === 200) {
            return { success: true, data: data };
        } else {
            return { success: false, error: 'Failed to get summary' };
        }
    } catch (error) {
        console.error('Error:', error);
        return { success: false, error: error.message };
    }
};

export const processFile = async (file, _lang) => {
    const result = await extractText(file);
    if (result.success) {
        const summaryResult = await getSummary(result.text);
        if (summaryResult.success) {
            return { success: true, data: summaryResult.data };
        } else {
            return { success: false, error: summaryResult.error };
        }
    } else {
        return { success: false, error: result.error };
    }
};
