import styled from "styled-components";
import { useState } from "react";

const Task = styled.div`
  position: relative;
`;

const RectangleParentRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px;
  gap: 10px;
  text-align: left;
  font-size: 12px;
  color: #000;
  font-family: Poppins;
`;

const Checkbox = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(242, 119, 98, 1);
  width: 17px;
  height: 17px;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? "rgba(242, 119, 98, 1)" : "transparent")};
`;

const TaskItem = ({ task, index }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked);
  };

  return (
    <RectangleParentRoot>
      <Checkbox
        aria-label={`Task ${index + 1}`}
        checked={isChecked}
        onClick={handleCheckboxClick}
      />
      <Task>{task}</Task>
    </RectangleParentRoot>
  );
};

export default TaskItem;
