import styled from "styled-components";
// import CustomButton1 from "../components/CustomButton1";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const YourReportIs = styled.div`
  position: relative;
  font-weight: 500;
`;
const PhcheckCircleThinIcon = styled.img`
  width: 80px;
  position: relative;
  height: 80px;
  overflow: hidden;
  flex-shrink: 0;
`;
const YourReportIsReadyParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 414px;
  height: 896px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  box-sizing: border-box;
  gap: 50px;
`;
const ReportReadyRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #f4efe9;
  height: 896px;
  overflow: hidden;
  text-align: left;
  font-size: 30px;
  color: #1d1928;
  font-family: Poppins;
`;

const ReportReady = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, file } = location.state || {};

  useEffect(() => {
    // Check the condition and navigate immediately if not met
    if (!data) {
      navigate('/', {replace: true});
      alert('No data');
      return; // Return to stop further execution of this effect
    }
    
    // If condition is met, set the timeout to navigate after the delay
    const timer = setTimeout(() => {
      navigate('/summary', { state: { data, file} , replace: true });
    }, 1200); // Change 3000 to the desired time in milliseconds
    
    // Cleanup the timer if the component is unmounted before the timer completes
    return () => clearTimeout(timer);
  }, [data, file, navigate]);
  
  // If the condition is not met, render null or a placeholder
  if (!data) {
    return null;
  }


  return (
    <ReportReadyRoot>
      <YourReportIsReadyParent>
        <YourReportIs>Your report is ready!</YourReportIs>
        <PhcheckCircleThinIcon alt="" src="/phcheckcirclethin.svg" />
        {/* <CustomButton1 /> */}
      </YourReportIsReadyParent>
    </ReportReadyRoot>
  );
};

export default ReportReady;
