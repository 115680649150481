import styled from "styled-components";
import { useState, useEffect, useRef } from 'react';

const ChatbotDrawer = () => {
    const [chatHistory, setChatHistory] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [chatStep, setChatStep] = useState(0);
    const [isThinking, setIsThinking] = useState(false);

    const predefinedAnswersList = [{
        "What is a hiatal hernia?": "A hiatal hernia is a condition where part of the stomach moves up into the chest area, causing discomfort.",
        "What symptoms might I experience with a hiatal hernia?": "Common symptoms include heartburn, acid reflux, difficulty swallowing, and chest pain.",
        "What causes a hiatal hernia?": "Hiatal hernias can be caused by age-related changes, injury, or increased pressure on the abdomen from coughing, vomiting, or straining during bowel movements.",
        },{
        "What can happen if a hiatal hernia is left untreated?": "If left untreated, a hiatal hernia can lead to complications such as severe acid reflux, esophagitis, and, in rare cases, esophageal cancer.",
        // "What are PPIs (Proton Pump Inhibitors)?": "PPIs are medications that reduce the production of stomach acid, helping to relieve symptoms of acid reflux and heartburn.",
        "Why is it important to take my medications as prescribed?": "Taking your medications as prescribed is crucial for managing symptoms and preventing complications associated with a hiatal hernia.",
        },{
        "What lifestyle and dietary changes should I follow?": "Recommended changes include eating smaller meals, avoiding trigger foods (like spicy or fatty foods), not lying down after eating, losing weight if needed, and avoiding smoking and alcohol.",
        "How often should I schedule follow-up appointments?": "It's important to schedule follow-up appointments as advised by your doctor to monitor your condition and adjust the treatment plan if necessary.",
    }];
    
    const predefinedAnswers = predefinedAnswersList[chatStep];
    const chatEndRef = useRef(null);

    const handleQuestion = (question, answer) => {

    setIsThinking(true);

    setTimeout(() => {
        setChatHistory(prevHistory => [...prevHistory, { question, answer: answer }]);
        setChatStep((chatStep + 1) % predefinedAnswersList.length);
        setIsThinking(false);
    }, 2500); // 2.5-second delay to simulate thinking

}

    const handleQuestionClick = (question) => {
        // setChatHistory([...chatHistory, { question }]);
        handleQuestion(question, predefinedAnswers[question]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (userInput.trim()) {
            // What are PPIs?
            handleQuestion(userInput, "PPIs are medications that reduce the production of stomach acid, helping to relieve symptoms of acid reflux and heartburn.");
            setUserInput("");
        }
    };

    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatHistory, isThinking]);

    const availableQuestions = Object.keys(predefinedAnswers).filter(
        question => !chatHistory.some(chat => chat.question === question)
    );

    return (
        <Drawer>
            <ChatbotHeader>Ask a question:</ChatbotHeader>
            <ChatbotContent>
                <QuestionContainer>
                    {Object.keys(predefinedAnswersList[0]).map((question, index) => (
                        <QuestionBalloon 
                            key={index} 
                            onClick={() => handleQuestionClick(question)}
                            disabled={chatHistory.some(chat => chat.question === question)}
                            selected={chatHistory.some(chat => chat.question === question)}
                        >
                            {question}
                        </QuestionBalloon>
                    ))}
                </QuestionContainer>
                {chatHistory.map((chat, index) => (
                    <div key={index}>
                        <ChatBubble user>
                            {chat.question}
                        </ChatBubble>
                        {chat.answer && (
                            <ChatBubble>
                                {chat.answer}
                            </ChatBubble>
                        )}
                    </div>
                ))}
                
                {isThinking && (
                    <ThinkingBubble>
                        <ThinkingIcon src="/skill-development.png" alt="Thinking..." />
                    </ThinkingBubble>
                )}
                {!isThinking && availableQuestions.length > 0 && chatHistory.length > 0 && (
                    <AvailableQuestionsContainer>
                        {availableQuestions.map((question, index) => (
                            <QuestionBalloon 
                                key={index} 
                                onClick={() => handleQuestionClick(question)}
                            >
                                {question}
                            </QuestionBalloon>
                        ))}
                    </AvailableQuestionsContainer>
                )}
                <div ref={chatEndRef} />
            </ChatbotContent>
            <ChatInputForm onSubmit={handleSubmit}>
                <ChatInput
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Seek Medical Knowledge"
                />
                <ChatSubmit type="submit">Ask</ChatSubmit>
            </ChatInputForm>
        </Drawer>
    );
}

const Drawer = styled.div`
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 350px;
  height: 500px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
`;

const ChatbotHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ChatbotContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ChatBubble = styled.div`
  background-color: ${({ user }) => (user ? '#fff0e0' : '#f0f0f0')};
  border-radius: 8px;
  padding: 10px;
  font-size: 12px;
  text-align: ${({ user }) => (user ? 'right' : 'left')};
  color: ${({ user }) => (user ? '#f27762' : '#000')};
  float: ${({ user }) => (user ? 'right' : 'left')};
  border: ${({ user }) => (user ? '1px solid #f27762' : 'none')};
  width: fit-content;
//   max-width: 70%;
`;

const ThinkingBubble = styled.div`
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  font-size: 12px;
  text-align: left;
  color: #000;
  float: left;
  border: none;
  width: fit-content;
`;

const ThinkingIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const QuestionBalloon = styled.div`
  background-color: ${({ selected }) => (selected ? '#f27762' : '#fff')};
  color: ${({ selected }) => (selected ? '#fff' : '#f27762')};
  border: 1px solid #f27762;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: left;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  margin: 5px;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const AvailableQuestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ChatInputForm = styled.form`
  display: flex;
  align-items: center;
  padding-top: 10px;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
`;

const ChatSubmit = styled.button`
  padding: 10px 15px;
  background-color: #f27762;
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
`;

export default ChatbotDrawer;
