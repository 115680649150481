import styled from "styled-components";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../components/CustomButton";
import { processFile } from '../service/fileHandler';

const HomeScreen = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef();

  // Function to trigger the file input dialog
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file selection and upload
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile || selectedFile.type !== 'application/pdf') {
      alert('Please upload a PDF file.');
      return;
    }

    navigate('/empowering-you');

    try {
      const response = await processFile(selectedFile);
      if (response.success) {
        navigate('/report-ready', { state: { data: response.data, file: selectedFile }, replace: true });
      } else {
        alert('File processing failed.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file.');
    }
  };

  return (
    <HomescreenRoot>
      <FrameParent>
        <Image5Wrapper>
          <Image5Icon alt="" src="/image-5@2x.png" />
        </Image5Wrapper>
        <MedicalInformationContainer>
          <MedicalInformation>Medical Information -</MedicalInformation>
          <MedicalInformation> you can follow and trust</MedicalInformation>
        </MedicalInformationContainer>
        <TransformingDoctorPatientCoContainer1>
          <TransformingDoctorPatientCoContainer>
            <TransformingDoctorPatientCo>
              Transforming doctor-patient communication,
            </TransformingDoctorPatientCo>
            <MedicalInformation>
              <BeA>{`be a `}</BeA>
              <Partner>Partner</Partner>
              <InYourJourney>{` in your journey, not just a Patient. `}</InYourJourney>
            </MedicalInformation>
          </TransformingDoctorPatientCoContainer>
        </TransformingDoctorPatientCoContainer1>
        <UploadAMedicalNoteImageOParent>
          <UploadAMedical>{`Upload a medical note: image or PDF `}</UploadAMedical>
          <CustomButton leftIcon="/solarexportlinear.svg" text="Get Started" onClick={handleButtonClick} />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept="application/pdf"
            onChange={handleFileChange}
          />
        </UploadAMedicalNoteImageOParent>
      </FrameParent>
      <ExploreOurSolution>{`Explore our solution, you deserve it, It’s YOUR Life `}</ExploreOurSolution>
      <Image18Icon alt="" src="/image-18@2x.png" />
    </HomescreenRoot>
  );
};

const Image5Icon = styled.img`
  width: 165px;
  position: relative;
  height: 41px;
  object-fit: cover;
  mix-blend-mode: multiply;
`;
const Image5Wrapper = styled.div`
  position: absolute;
  top: 28px;
  left: 23px;
  width: 161px;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MedicalInformation = styled.p`
  margin: 0;
`;
const MedicalInformationContainer = styled.div`
  position: absolute;
  top: 177px;
  left: 14px;
  font-weight: 600;
`;
const TransformingDoctorPatientCo = styled.p`
  margin: 0;
  font-weight: 500;
`;
const BeA = styled.span`
  font-weight: 500;
`;
const Partner = styled.b`
  font-family: Poppins;
`;
const InYourJourney = styled.span`
  font-weight: 500;
  font-family: Poppins;
`;
const TransformingDoctorPatientCoContainer = styled.span`
  width: 100%;
`;
const TransformingDoctorPatientCoContainer1 = styled.div`
  position: absolute;
  top: 304px;
  left: 21px;
  font-size: 15px;
  color: #1d1928;
  display: flex;
  align-items: center;
  width: 380px;
`;
const UploadAMedical = styled.div`
  align-self: stretch;
  position: relative;
`;
const UploadAMedicalNoteImageOParent = styled.div`
  position: absolute;
  top: 406px;
  left: 40px;
  width: 334px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 17px;
  text-align: center;
  font-size: 10px;
  color: #f27762;
`;
const FrameParent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 414px;
  height: 896px;
`;

const ExploreOurSolution = styled.div`
  position: absolute;
  top: 498px;
  left: 42px;
  font-size: 12px;
  color: #f27762;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 334px;
`;
const Image18Icon = styled.img`
  position: absolute;
  top: 537px;
  left: -176px;
  width: 773px;
  height: 419px;
  object-fit: cover;
`;
const HomescreenRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #f4efe9;
  height: 1100px;
  overflow: hidden;
  text-align: left;
  font-size: 31px;
  color: #003b4a;
  font-family: Poppins;
`;

export default HomeScreen;
