import styled from "styled-components";
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import WhatDoIHave from "../components/WhatDoIHave";
import WhatDoINeedToDo from "../components/WhatDoINeedToDo";
import WhyIsItImportant from "../components/WhyIsItImportant";
import SizesThemeghostStatedef from "../components/SizesThemeghostStatedef";
import OriginalDoc from "../components/OriginalDoc";
import LangButton from "../components/LangButton";
import ChatbotDrawer from "../components/ChatBotDrawer";

const Summary = () => {
  const location = useLocation();
  const [lang, setLang] = useState('en');
  const { data: data_all = {}, file } = location.state || {};
  const navigate = useNavigate();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const isCoreOnly = process.env.REACT_APP_ONLY_CORE === 'true';
  const data = data_all[lang];

  const updateLang = (lang) => {
    setLang(lang);
  };

  const openDoc = () => {
    navigate('/original-doc', { state: { file } });
  };

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  return (
    <SummaryRoot>
      <ReportTitle>Hi {data['name']}, see your report:</ReportTitle>
      <ReportContainer>

        <MainColumn>

          <WhatDoIHave findings={data['findings']} />
          <WhatDoINeedToDo tasks={data['actions']} />
          <WhyIsItImportant why={data['summary']} />
          <ButtonsLine>
            <RateYourReportParent>
              <RateYourReport>Rate your report:</RateYourReport>
              <SizesThemeghostStatedef
                plus="/thumbsup.svg"
                sizesThemeghostStatedefBorder="unset"
              />
              <SizesThemeghostStatedef
                plus="/thumbsdown.svg"
                sizesThemeghostStatedefBorder="unset"
              />
              <SizesThemeghostStatedef
                plus="/copy.svg"
                sizesThemeghostStatedefBorder="unset"
              />
              <ButtonIcon>
                <ClarityexportLineIcon alt="" src="/clarityexportline.svg" />
              </ButtonIcon>
            </RateYourReportParent>
            <ButtonIcon1>
              <ClarityexportLineIcon alt="" src="/arrowrotateforward.svg" />
            </ButtonIcon1>
            <LangButton currentLang={lang} targetLang="en" onClick={() => updateLang('en')}>
              English
            </LangButton>
            <LangButton currentLang={lang} targetLang="es" onClick={() => updateLang('es')}>
              Spanish
            </LangButton>
          </ButtonsLine>
        </MainColumn>
        <AdditionalInfoColumn>
          <div onClick={() => openDoc()}>
            <OriginalDoc image3={isCoreOnly ? file : "/Untitled design.png"} />
          </div>
        </AdditionalInfoColumn>
      </ReportContainer>
      
      {!isCoreOnly && <>
      <FloatingButton onClick={toggleChatbot}>
        <StethoscopeIcon alt="" src="/ciuservoicedoctor.svg" />
      </FloatingButton>

      {isChatbotOpen && (
        <ChatbotDrawer />
      )}
    
    </>}
    </SummaryRoot>
  );
};

const SummaryRoot = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-color: #f4efe9;
  overflow: hidden;
  text-align: left;
  font-size: 16px;
  color: #141414;
  font-family: Poppins;
  display: flex;
  flex-direction: column;
`;


const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 24px;
  }
`;

const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 414px;
`;

const AdditionalInfoColumn = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ReportTitle = styled.div`
  padding: 20px;
  position: relative;
  font-weight: 600;
`;

const RateYourReport = styled.div`
  align-self: stretch;
  width: 100px;
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const ClarityexportLineIcon = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
`;

const ButtonIcon = styled.div`
  width: 24px;
  border-radius: 22px;
  background-color: #fff;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  box-sizing: border-box;
`;

const RateYourReportParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

const ButtonIcon1 = styled.div`
  width: 24px;
  border-radius: 22px;
  background-color: #fff;
  border: 1px solid #c9d9f2;
  box-sizing: border-box;
  height: 24px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
`;

const ButtonsLine = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: #000;
`;

const FloatingButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #f27762;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 999;  
`;

const StethoscopeIcon = styled.img`
  width: 30px;
  height: 30px;
  overflow: hidden;
`;

export default Summary;
