import styled from "styled-components";
import { useState } from "react";

const WhyIsIt = styled.div`
  position: relative;
`;
const WhyIsItImportantWrapper = styled.div`
  border-radius: 0px 8px 8px 0px;
  background-color: #fdf1f3;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
`;

const TextWhy = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px;
  color: #000;
`;
const WhereCanI = styled.div`
  position: relative;
  font-weight: 500;
`;
const WhereCanISeeThisWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0px 20px;
  color: #f27762;
`;
const FrameParentRoot = styled.div`
  align-self: stretch;
  box-shadow: 1px 1px 3px rgba(123, 123, 123, 0.25);
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0px;
  gap: 12px;
  text-align: left;
  font-size: 12px;
  color: #c12f49;
  font-family: Poppins;
`;

const WhyIsItImportant = ({why}) => {

    const [isCollapsed, setIsCollapsed] = useState(false);
  
    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };
    
  return (
    <FrameParentRoot>
      <WhyIsItImportantWrapper onClick={toggleCollapse}>
        <WhyIsIt>Why is it important?</WhyIsIt>
        <div>{isCollapsed ? '▼' : '▲'}</div>
      </WhyIsItImportantWrapper>
      {!isCollapsed && (
        <>
      <whyParent>
        <WhyIsIt>
          <TextWhy>
            {why}
          </TextWhy>
        </WhyIsIt>
      </whyParent>
      <WhereCanISeeThisWrapper>
        <WhereCanI>Where can i see this?</WhereCanI>
      </WhereCanISeeThisWrapper>
      </>
      )}
    </FrameParentRoot>
  );
};

export default WhyIsItImportant;
