import styled from "styled-components";

const SolarexportLinearIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const GetStarted = styled.div`
  position: relative;
  font-weight: 500;
`;
const SolarexportLinearParentRoot = styled.div`
  width: 323px;
  border-radius: 32px;
  background-color: #f27762;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  box-sizing: border-box;
  gap: 10px;
  text-align: left;
  font-size: 24px;
  color: #fff;
  font-family: Poppins;
  cursor: pointer; /* Make the button look clickable */
`;

const CustomButton = ({ leftIcon, text, onClick }) => {
  return (
    <SolarexportLinearParentRoot onClick={onClick}>
      <SolarexportLinearIcon alt="" src={leftIcon} />
      <GetStarted>{text}</GetStarted>
    </SolarexportLinearParentRoot>
  );
};

export default CustomButton;
