import styled from "styled-components";

const PlusIcon = styled.img`
  width: 16px;
  position: relative;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
`;
const SizesThemeghostStatedefRoot = styled.div`
  width: 24px;
  border-radius: 22px;
  background-color: #fff;
  border: 1px solid #c9d9f2;
  box-sizing: border-box;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: ${(p) => p.sizesThemeghostStatedefBorder};
`;

const SizesThemeghostStatedef = ({ plus, sizesThemeghostStatedefBorder }) => {
  return (
    <SizesThemeghostStatedefRoot
      sizesThemeghostStatedefBorder={sizesThemeghostStatedefBorder}
    >
      <PlusIcon alt="" src={plus} />
    </SizesThemeghostStatedefRoot>
  );
};

export default SizesThemeghostStatedef;
