import styled from "styled-components";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import React, { useEffect, useMemo } from 'react';

const OriginalDoc1 = styled.div`
  position: relative;
`;

const LinkPlaceHolder = styled.div`
  position: relative;
  color: blue; // Makes the text blue
  text-decoration: underline; // Underlines the text to look like a link
  cursor: pointer; // Changes cursor to a pointer on hover
`;

const OriginalDocWrapper = styled.div`
  border-radius: 0px 8px 8px 0px;
  background-color: #f0f0f0;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  z-index: 0;
`;
const Image3Icon = styled.img`
  width: 414px;
  position: relative;
  height: 575px;
  object-fit: cover;
  z-index: 1;
`;

const PDFContainer = styled.div`
  width: 414px;
  position: relative;
  height: 575px;
  object-fit: cover;
  z-index: 1;
`;
// const FrameChild = styled.div`
//   width: 337px;
//   position: absolute;
//   margin: 0 !important;
//   top: 194px;
//   left: 14px;
//   background-color: rgba(248, 180, 192, 0.2);
//   height: 35px;
//   z-index: 2;
// `;
// const FrameItem = styled.div`
//   width: 341px;
//   position: absolute;
//   margin: 0 !important;
//   top: 253px;
//   left: 10px;
//   background-color: rgba(255, 206, 144, 0.2);
//   height: 66px;
//   z-index: 3;
// `;
// const FrameInner = styled.div`
//   width: 337px;
//   position: absolute;
//   margin: 0 !important;
//   top: 160px;
//   left: 14px;
//   background-color: rgba(39, 180, 239, 0.2);
//   height: 13px;
//   z-index: 4;
// `;
const FrameParentRoot = styled.div`align-self: stretch;
  box-shadow: 1px 1px 3px rgba(123, 123, 123, 0.25);
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 0px;
  position: relative;
  gap: 12px;
  text-align: left;
  font-size: 14px;
  color: #000;
  font-family: Poppins;
  align-self: ${(p) => p.propAlignSelf}
  flex: ${(p) => p.propFlex}
`;



const OriginalDoc = ({ image3, propAlignSelf, propFlex }) => {
  const isCoreOnly = process.env.REACT_APP_ONLY_CORE === 'true';

    // Extract the file from the state
    // const { file } = location.state || {};
  
    // Memoize the file URL to ensure it's stable
    const fileUrl = useMemo(() => {
      if(!isCoreOnly){
        return `${process.env.PUBLIC_URL}/Untitled design.pdf`; //Used for demo
      }
      if (image3 && image3.type === 'application/pdf') {
        return URL.createObjectURL(image3);
      }
      return null;
    }, [image3,isCoreOnly]);
  
    // Clean up the URL object when the component unmounts to avoid memory leaks
    useEffect(() => {
      if (fileUrl) {
        return () => {
          URL.revokeObjectURL(fileUrl);
        };
      }
    }, [fileUrl,isCoreOnly]);

  return (
    <FrameParentRoot propAlignSelf={propAlignSelf} propFlex={propFlex}>
      <OriginalDocWrapper>
        <OriginalDoc1>Addition Information</OriginalDoc1>
      </OriginalDocWrapper>
  
  {isCoreOnly && 
  <LinkPlaceHolder>
    Click here for more details
  </LinkPlaceHolder>
  }
  
      {isCoreOnly?
      <PDFContainer>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={fileUrl}
                    // plugins={[defaultLayoutPluginInstance]}
                  />
              </Worker>
              </PDFContainer>
      :
      <Image3Icon alt="" src={image3} />
      }
      {/* <FrameChild /> */}
      {/* <FrameItem /> */}
      {/* <FrameInner /> */}
    </FrameParentRoot>
  )
};

export default OriginalDoc;
